
.chip-wrap {
    margin-top: 30px;
    margin-bottom: 14px;
    width: 160px;
}
.list-item + .list-item {
    margin-top: 28px;
}
@media (min-width: 768px) {
    .chip-wrap {
        margin-top: 40px;
        margin-bottom: 16px;
        width: 206px;
    }
    .list-item + .list-item {
        margin-top: 36px;
    }
}
