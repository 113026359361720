
.v-data-table {
    td {
        background-color: #b0b0b0 !important;
    }
    .bg-a {
        background-color: var(--v-secondary-lighten4) !important;
    }
    .bg-b {
        background-color: #ffca99 !important;
    }
    .bg-c1 {
        background-color: #ffecfa !important;
    }
    .bg-c2 {
        background-color: #fdff9e !important;
    }
    .bg-d {
        background-color: var(--v-primary-lighten4) !important;
    }
    .bg-e {
        background-color: var(--v-tertiary-lighten4) !important;
    }
    .bg-f1 {
        background-color: #bbeac0 !important;
    }
    .bg-f2 {
        background-color: #72ddff !important;
    }
    .bg-g {
        background-color: #f0a7f1 !important;
    }
    .bg-h {
        background-color: #ffda57 !important;
    }
    .empty {
        background-color: #fff !important;
    }
}
