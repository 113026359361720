
.overview-table {
    margin-top: 20px;
    tbody > tr > th {
        width: 30%;
    }
}
@media (min-width: 768px) {
    .overview-table tbody > tr > th {
        width: 15%;
    }
}
@media (min-width: 1024px) {
    .overview-table {
        margin-top: 42px;
    }
}
