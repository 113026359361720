
.map-wrap {
    position: relative;
    height: 300px;
    overflow: hidden;
}
.map {
    display: block;
    width: 100%;
    height: 406px;
    border: none;
    position: absolute;
    left: 0;
    bottom: 0;
}
.map-card {
    font-size: var(--txt-font-size-lg);
    color: #fff;
    padding: 24px 12px;
    width: 100%;
    max-width: calc(100% - 12px * 2);
    display: flex;
    align-items: center;
    margin: 20px auto 0;
    background: url("/images/sub/business/direction/map-card.png") no-repeat right;
    background-size: auto 100%;
    &__inner {
        width: 100%;
    }
}
@media (min-width: 768px) {
    .map-wrap {
        height: 400px;
    }
    .map {
        height: 506px;
    }
}
@media (min-width: 1024px) {
    .map-wrap {
        height: 586px;
    }
    .map {
        height: 692px;
    }
    .map-card {
        font-size: 18px;
        max-width: 996px;
        height: 194px;
        margin-top: 30px;
        padding: 0 24px;
    }
}
