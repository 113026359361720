
.v-tab--button {
    --tab-margin-compact: 6px;
}
::v-deep {
    .v-slide-group__content {
        flex-wrap: wrap !important;
    }
}
.v-tabs--grow > .v-tabs-bar .v-tab {
    flex: 0 0 calc(33.33% - 8px) !important;
    justify-content: center;
    &:first-child,
    &:nth-child(4) {
        margin-left: 0 !important;
    }
    &:last-child,
    &:nth-child(3) {
        margin-right: 0 !important;
    }
}
@media (min-width: 768px) {
    .v-tab--button {
        --tab-margin-compact: 0 6px;
    }
    ::v-deep {
        .v-slide-group__content {
            flex-wrap: wrap !important;
            justify-content: center;
            margin: 0;
        }
    }
    .v-tabs--grow > .v-tabs-bar .v-tab {
        width: initial;
        flex: 1 1 !important;
        &:nth-child(3),
        &:nth-child(4) {
            margin: var(--tab-margin-compact) !important;
        }
    }
}
